import React from 'react'
import { Container, Card, Row, Col } from 'react-bootstrap';
import {
	FaFileInvoiceDollar, FaTasks, FaFileUpload,FaHandsHelping,FaThumbsUp
} from "react-icons/fa";

const comoFunciona = ({ id }) => {

	return (

		<section className="page-section" >
			<div className="name-section" id={id}>
				<Container >
					<h2 className="text-center mt-0 title-general">¿Cómo funciona Financiae?</h2>
					<hr className="divider my-4" />
					<Row>
						<Col md={12}>
							<Card>
								<Card.Body>
									<Row>
										<Col xs={1}><FaFileUpload color="#5cb532" /></Col>
										<Col xs={11}>
											Carga tu certificado digital para obtener automáticamente tus registros de venta.
										</Col>
									</Row>
									<br />
									<Row>
										<Col xs={1}><FaTasks color="#5cb532" /></Col>
										<Col xs={11}>
											Selecciona las empresas del certificado que quieras mostrar al factoring.
										</Col>
									</Row>
									<br />
									<Row>
										<Col xs={1}><FaFileInvoiceDollar color="#5cb532" /></Col>
										<Col xs={11}>
											Solicita cotizaciones al factoring o revisa las que ya se han generado.
										</Col>
									</Row>
									<br />
									<Row>
										<Col xs={1}><FaThumbsUp color="#5cb532" /></Col>
										<Col xs={11}>
											Acepta o rechaza las cotizaciones vigentes.
										</Col>
									</Row>
									<br />
									<Row>
										<Col xs={1}><FaHandsHelping color="#5cb532" /></Col>
										<Col xs={11}>
											Cede los documentos de las cotizaciones aceptadas directamente desde la plataforma:
										</Col>
									</Row>
									<br />
									<Row>
										<Col xs={11}>
											* Si facturas a través del portal gratuito del SII, tu factura se descarga automáticamente para ser cedida.
										</Col>
									</Row>
									<br />
									<Row>
										<Col xs={11}>
											* Si facturas a través de un software de mercado, deberás obtener directamente desde ahí el XML de la(s) factura(s) que quieras vender para cargarla(s) en Financiae y luego ceder.
										</Col>
									</Row>
								</Card.Body>
							</Card>
						</Col>
					</Row>


				</Container >
			</div>


		</section >
	)
}

export default comoFunciona
