import React, { useState } from 'react'
import { Container, Row, Col, Form, Button, Spinner } from 'react-bootstrap';
import { FaPhone, FaEnvelope, FaCheckCircle } from "react-icons/fa";
import { useForm } from 'react-hook-form'

const Contacto = ({ id }) => {

    const [mensaje, setMensaje] = useState();
    const [cargando, setCargando] = useState(false);
    const { register, errors, handleSubmit } = useForm();

    const enviarFormulario = (data) => {
        setCargando(true)
        const url = 'https://hook.abstrahere.com/v1/sendmail/staticweb';
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': 'At6WuIg1dT8osNMidRNwU447KWsCpiFe15E6nCXs'
            }
        })
            .then((resp) => {
                if (resp.status === 200 && resp.ok) {
                    setMensaje(<>Mensaje enviado correctamente <FaCheckCircle /></>)
                } else {
                    throw Error(resp?.statusText);
                }
            })
            .catch(error => {
                console.log('error en la petición');
                console.log(error);
                setMensaje(<>No se pudo enviar el mensaje, vuelva a intentarlo más tarde</>)
            })
            .finally(() => {
                setCargando(false);
                setTimeout(() => {
                    setMensaje('')
                }, 40000);
            });
    }


    const onSubmit = (data, e) => {

        const enviar = {
            ...data,
            web: 'financiae.cl'
        }
        enviarFormulario(enviar)

        e.target.reset();

    }

    return (
        <section className="page-section testup">
            <div className="name-section" id={id}>
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={8} className="text-center">
                            <h2 className="mt-0">¡Mantengámonos en contacto!</h2>
                            <hr className="divider my-4" />
                            <p className="text-muted mb-5">¿Listo para comenzar a utilizar Financiae? ¡Llámenos o envíenos un correo electrónico y nos pondremos en contacto con usted lo antes posible!</p>
                        </Col>
                    </Row>
                    <Row >
                        <Col lg={3} md={6} xs={12} className="text-center">
                            <FaPhone size={36} className="mb-3 text-muted" />
                            <ul className="telefonos-li">
                                <li>
                                    (+56 41) 2560468
                                </li>
                                <li>
                                    (+56 2) 32103189
                                </li>
                            </ul>
                        </Col>
                        <Col lg={3} md={6} xs={12} className="text-center">
                            <FaEnvelope size={36} className="mb-3 text-muted" />
                            <a className="d-block" href="mailto:contacto@abstrahere.com">contacto@abstrahere.com</a>
                        </Col>

                        <div className="separacion-md-menos" />
                        <div className="separacion-md-menos" />

                        <Col lg={6} md={12}>
                            <form className="my-2" onSubmit={handleSubmit(onSubmit)}>
                                <Row >
                                    <Col md={6} sm={12} className="mb-4">
                                        <Form.Control
                                            type="text"
                                            name="nombre"
                                            ref={register({
                                                required: {
                                                    value: true,
                                                    message: 'Nombre es requerido'
                                                }
                                            })}
                                            isInvalid={errors.nombre}
                                            placeholder="Nombre"
                                            className="incon" />
                                        <Form.Control.Feedback type="invalid" className="mensaje-validacion">
                                            {errors?.nombre?.message}
                                        </Form.Control.Feedback>
                                    </Col>
                                    <Col md={6} sm={12} className="mb-4">
                                        <Form.Control
                                            type="text"
                                            name="empresa"
                                            ref={register({
                                                required: {
                                                    value: true,
                                                    message: 'Empresa es requerida'
                                                }
                                            })}
                                            isInvalid={errors.empresa}
                                            placeholder="Empresa"
                                            className="incon" />
                                        <Form.Control.Feedback type="invalid" className="mensaje-validacion">
                                            {errors?.empresa?.message}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6} sm={12} className="mb-4">
                                        <Form.Control
                                            type="text"
                                            name="telefono"
                                            ref={register({
                                                required: {
                                                    value: true,
                                                    message: 'Teléfono es requerido'
                                                }
                                            })}
                                            isInvalid={errors.telefono}
                                            placeholder="Teléfono"
                                            className="incon" />
                                        <Form.Control.Feedback type="invalid" className="mensaje-validacion">
                                            {errors?.telefono?.message}
                                        </Form.Control.Feedback>
                                    </Col>
                                    <Col md={6} sm={12} className="mb-4">
                                        <Form.Control
                                            type="text"
                                            name="email"
                                            ref={register({
                                                required: {
                                                    value: true,
                                                    message: 'Email es requerido'
                                                },
                                                pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                    message: "Email no válido"
                                                }
                                            })}
                                            isInvalid={errors.email}
                                            placeholder="Email"
                                            className="incon" />
                                        <Form.Control.Feedback type="invalid" className="mensaje-validacion">
                                            {errors?.email?.message}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="mb-4">
                                        <Form.Control
                                            as="textarea"
                                            rows="4"
                                            name="mensaje"
                                            ref={register({
                                                required: {
                                                    value: true,
                                                    message: 'Mensaje es requerido'
                                                }
                                            })}
                                            isInvalid={errors.mensaje}
                                            placeholder="Mensaje"
                                            className="incon" />
                                        <Form.Control.Feedback type="invalid" className="mensaje-validacion">
                                            {errors?.mensaje?.message}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Row>
                                <div>
                                    <Button type="submit" size="lg" block className="btn-solucion no-bradius no-border"
                                        disabled={cargando}
                                    >
                                        {
                                            !cargando
                                                ? <>Enviar mensaje</>
                                                : <>
                                                    <Spinner
                                                        as="span"
                                                        animation="grow"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                        style={{ marginBottom: '4px' }}
                                                    /> Enviando...
                                                </>
                                        }
                                    </Button>
                                </div>

                                {!!mensaje &&
                                    <div className="mensaje-envio">
                                        {mensaje}
                                    </div>
                                }

                            </form>
                        </Col>


                    </Row>
                </Container>
            </div>
        </section>
    )
}

export default Contacto
