import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Scroller from "../components/scroller"

import Inicio from '../components/sections/Inicio';
import Sobre from '../components/sections/Sobre';
import ComoFunciona from '../components/sections/ComoFunciona';
import Contacto from '../components/sections/Contacto';

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    Scroller.handleAnchorScroll = Scroller.handleAnchorScroll.bind(this)
  }


  render() {
    return (
      <Layout>
        <SEO title="Financiae" />
        <Inicio id="inicio" />
        <Sobre id="sobre" scroll={Scroller.handleAnchorScroll} />
        <ComoFunciona id="comoFunciona" scroll={Scroller.handleAnchorScroll} />
        <Contacto id="contacto" />
      </Layout>
    )
  }
}


export const imageData = graphql`
  query {
    images: allFile(filter: {relativePath: {glob: "portfolio/fullsize/*.jpg"}}, sort: {fields: name}) {
      edges {
        node {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
