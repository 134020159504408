import React from 'react'
import Scroller from './../scroller'
import { Col, Row, Container } from "react-bootstrap"
const Inicio = ({ id }) => {
    return (

        <header className="masthead" id={id}>
            <Container className="h-100">
                <Row className="h-100 align-items-center justify-content-center text-center">
                    <Col lg={11} className="align-self-end">
                        <h1 className="text-uppercase text-white font-weight-bold">
                            EL FACTORING ELECTRÓNICO QUE NECESITAS PARA TUS CLIENTES
                        </h1>
                    </Col>
                    <Col lg={8} className="align-self-baseline">
                        <br />
                        <p >
                            Obtén tu espacio digital diseñado especialmente para empresas factoring y sus clientes, cotizando documentos y realizando cesiones en un solo lugar
                        </p>
                        <a className="btn btn-outline-light  js-scroll-trigger" href="#sobre" onClick={Scroller.handleAnchorScroll}>
                            Saber más
                        </a>
                    </Col>
                </Row>
            </Container>
        </header>

    )
}

export default Inicio
