import React from 'react'
import { Container, Card, Row, Col } from 'react-bootstrap';
import {
	FaFileInvoiceDollar, FaTasks, FaFileImport, FaSuitcase, FaFileUpload,
	FaUsersCog, FaShareSquare
} from "react-icons/fa";
import { MdWeb } from "react-icons/md";
import { graphql, useStaticQuery } from 'gatsby'
import Image from 'gatsby-image'

const Sobre = ({ id }) => {

	const getImagenes = graphql`
	{
		factoring:file(relativePath: {eq: "factoring.png"}) {
			childImageSharp {
				fluid(maxWidth: 220) {
					...GatsbyImageSharpFluid
				}
			}
		}
		cliente:file(relativePath: {eq: "cliente.png"}) {
			childImageSharp {
				fluid(maxWidth: 220) {
					...GatsbyImageSharpFluid
				}
			}
		}

	}`
	const data = useStaticQuery(getImagenes);
	const { factoring, cliente } = data;
	return (

		<section className="page-section" >
			<div className="name-section" id={id}>
				<Container >
					<h2 className="text-center mt-0 title-general">¿Qué es Financiae?</h2>
					<hr className="divider my-4" />
					<Row className="mb-5">
						<Col md={{ span: 8, offset: 2 }}>
							<p className="text-center">
								Es una plataforma web que genera un entorno digital dedicado para el factoring y sus clientes. Nuestro servicio tiene el propósito de simplificar las tareas de cotización, venta y cesión de documentos, proporcionando herramientas al alcance de la mano para optimizar estas operaciones.
							</p>
						</Col>
					</Row>

					<Row className="m-5">
						<Col>
							<h4 className="text-center mt-4 text-primary">¡TENEMOS LAS FUNCIONES QUE CADA USUARIO NECESITA!</h4>
						</Col>
					</Row>

					<Row className="mb-5">

						<Col md={6}>
							<Card>
								<Card.Header className="py-5">
									<div style={{ maxWidth: 140, margin: 'auto' }}>
										<Image fluid={factoring.childImageSharp.fluid} />
									</div>
								</Card.Header>
								<Card.Body>
									<h4 className="mt-0 mb-4  text-center text-info font-weight-bold">FACTORING</h4>
									<Row>
										<Col xs={1}><FaSuitcase color="#0089e9" /></Col>
										<Col xs={11}>
											Listado de clientes registrados y acceso a sus registros de venta
										</Col>
									</Row>
									<br />
									<Row>
										<Col xs={1}><FaFileImport color="#0089e9" /></Col>
										<Col xs={11}>
											Envío especializado de cotizaciones según documentos de interés seleccionados
										</Col>
									</Row>
									<br />
									<Row>
										<Col xs={1}><FaUsersCog color="#0089e9" /></Col>
										<Col xs={11}>
											Multi-usuario con permisos para administrador y ejecutivos
										</Col>
									</Row>
									<br />
									<Row>
										<Col xs={1}><MdWeb color="#0089e9" /></Col>
										<Col xs={11}>
											Servicio 100% web
										</Col>
									</Row>
								</Card.Body>
							</Card>
						</Col>

						<Col md={6}>
							<Card>
								<Card.Header className="py-5">
									<div style={{ maxWidth: 140, margin: 'auto' }}>
										<Image fluid={cliente.childImageSharp.fluid} />
									</div>
								</Card.Header>
								<Card.Body>
									<h4 className="mt-0 mb-4 text-success text-center font-weight-bold">CLIENTES FACTORING</h4>
									<Row>
										<Col xs={1}><FaFileUpload color="#5cb532" /></Col>
										<Col xs={11}>
											Carga de certificado digital para obtención automática de registros de venta
										</Col>
									</Row>
									<br />
									<Row>
										<Col xs={1}><FaTasks color="#5cb532" /></Col>
										<Col xs={11}>
											Soporte para múltiples empresas por usuario
										</Col>
									</Row>
									<br />
									<Row>
										<Col xs={1}><FaShareSquare color="#5cb532" /></Col>
										<Col xs={11}>
											Cesión y re-cesión de documentos al factoring en un click
										</Col>
									</Row>
									<br />
									<Row>
										<Col xs={1}><FaFileInvoiceDollar color="#5cb532" /></Col>
										<Col xs={11}>
											Solicitud, aceptación y rechazo de cotizaciones para financiamiento de facturas
										</Col>
									</Row>
								</Card.Body>
							</Card>
						</Col>
					</Row>

				</Container >
			</div>


		</section >
	)
}

export default Sobre
